<template>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('employees.employees')"
                     :description="$t('employees.fromHereYouCanControlEmployees')" />
        <indexTable :searchText="$t('employees.FindAnEmployee')"
                    :emptyTableText="$t('employees.thereAreNoEmployeess')"
                    :localData="true"
                    :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
                    :buttonRole="$user.role.employees_add"
                    :cloumns="[
                     {column: 'name',    title:$t('employees.name') ,type: 'mainLink' , sort: true },
                     {column: 'job_title', title:$t('employees.JobTitle')      ,type: 'text'     , sort: true, icon: 'user-tie' },
                     {column: 'salary',    title:$t('employees.salary')       ,type: 'text'     , sort: true, icon: 'coins' },
                     {column: 'balance',    title:$t('employees.Balance')       ,type: 'text'     , sort: true, icon: 'usd-square' },
                     {column: 'stat',    title:$t('employees.active')      ,type: 'stat'     , sort: true, hideMobile: true },
                     {column: 'options', title:$t('employees.settings'), type: 'options', options: [
                         {name: 'show'},
                         {name: 'edit',   role: $user.admin || $user.role.employees_edit},
                         {name: 'link',   role: $user.admin || $user.role.employees_edit,title: $t('employees.AddASalary'), icon: 'money-check-alt', link:'/employeesSalaries/create?employee_id='},
                         {name: 'link',   role: $user.admin || $user.role.employees_edit, title: $t('employees.incentive'), icon: 'hand-holding-usd', link:'/employeesIncentives/create?employee_id='},
                         {name: 'link',   role: $user.admin || $user.role.employees_edit, title: $t('employees.deduct'), icon: 'funnel-dollar', link:'/employeesDeductions/create?employee_id='},
                         {name: 'link',   role: $user.admin || $user.role.employees_edit, title:$t('employees.BalanceWithdrawal'), icon: 'dollar-sign', link:'/employeesWithdrawals/create?employee_id='},
                         {name: 'delete', role: $user.admin || $user.role.employees_delete},
                     ]}

                   ]"
                    :deleteText="{
                    attention: $t('allerts.Attention'),
                    areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
                    close: $t('allerts.close'),
                    confirm: $t('allerts.confirm'),
                    loading: $t('allerts.loading'),
                   }" />
      </div>
    </div>

  </div>

</template>
<script>

  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'

  export default {
    data() {
      return {
        path: '/employees'
      }
    },
    components: {
      indexHeader,
      indexTable,
    }
  }

</script>
